var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticClass: "page-user"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('User')) + " " + _vm._s(_vm.user.lastname) + " ")])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.globalErrorMessage ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.globalErrorMessage) + " ")]) : _vm._e()], 1), _vm.user ? _c('UserProfile', {
    attrs: {
      "user-u-u-i-d": _vm.user._id
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }